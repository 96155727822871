@import './../../../lib/styles/mixins';
.wrapper .content main {
    margin: 0 auto;
    .expandedContainer {
        width: 100%;
        height: 100%;
        overflow: auto;
    }
}
.wrapper .content main .container {
    height: 100%;
    margin: 0 auto;
}

main > .wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: calc(100vh - 100px);
}
.pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 16px);
    height: 40px;
    padding: 0 25px;
    background-color: #fff;
    border-top: 1px solid #898989;
    &-select {
    }
    &-pages {
        button {
            width: 30px;
            height: 24px;
            margin:5px;
            background-color: transparent;
            color: $menu-default-background;
            box-shadow: 0 0 4px 0 #00000040;
            &:hover {
                background-color: #7A8A97;
                color: #F2F7FB;
                img {
                    filter: invert(.9);
                }
            }
            &:disabled {
                opacity: .6;
                pointer-events: none;
            }
            &.active {
                color: #DAE0E4;
                background-color: $menu-default-background;
            }
        }
    }
}

