@import './../../lib/styles/colors';
@import './../../lib/styles/mixins';

$menu-padding: 13px;
$transition-duration: .15s;
.nav {
  position: fixed;
  top: 0;
  //width: 100%;
  height: 100%;
  color: #fff;
  width: $open-menu-width;
  transition: $transition-duration width;
  z-index: 1;
  &.open {
    nav {
      overflow: auto;
    }
    .title-as-btn {
      background: $button-active;
      margin-bottom: 5px;
    }
    .dashboards, .templates {
      &-list h4 {
        display: none;
      }
    }
  }
  &.close {
    width: $closed-menu-width;
    transition: $transition-duration width;
    nav {
      align-items: center;
      .repository-container {
        width: 100%;
      }
      .title-as-btn {
        justify-content: center;
        height: $closed-menu-width;
        span {
          display: none;
        }
        &.my-dashboard  {
          position: relative;
          span {
            display: initial;
            position: absolute;
            left: 100%;
            top: 0;
            opacity: 0;
            text-align: center;
            width: 100px;
            padding: 5px;
            pointer-events: none;
            background: $menu-default-background;
            color: #fff;
            transition: $transition-duration opacity;
          }
          &:hover {
            span, label {
              opacity: 1;
              transition: $transition-duration opacity;
            }
          }
        }

      }
      label.title-as-btn  {
        position: relative;
        span {
          display: initial;
          position: absolute;
          left: 100%;
          top: 0;
          text-align: center;
          width: 100px;
          padding: 5px;
          opacity: 0;
          background: $menu-default-background;
          color: #fff;
          transition: $transition-duration opacity;
        }
        &:hover {

          span, label {
            opacity: 1;
            transition: $transition-duration opacity;
          }
        }
      }
    }
    .dashboards, .templates {
      position: relative;
      width: 100%;
      &-list h4 {
        padding: 5px 20px;
      }
    }
    #switch-user,
    .repository-container {
      position: relative;
      .dashboards-list,
      .templates-list {
        img {
          display: none;
        }
        position: absolute;
        margin: 0;
        width: 200px;
        left: 100%;
        top: 0;
        background: $menu-default-background;
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        .categories-list a {
          padding: 5px 10px 7px 15px;
        }
      }
      &:hover .dashboards-list,
      &:hover .templates-list {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        transition: $transition-duration opacity;
        details, summary, .categories-list  {
          margin: 0;
          padding: 5px 0;

        }
        details:last-child {
          padding-bottom: 0;
        }
        summary {
          padding: 10px 5px;
        }
        .categories-list {
          padding: 8px 0 8px 8px;

          &:hover {
            background-color: $button-hovered;
          }
          &:active, &.active {
            background-color: $button-active;
          }
        }
      }
    }
  }
  nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $menu-default-background;
    pointer-events: auto;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    h3 {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
    }
    .title-as-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 40px;
      font-size: 16px;
      color: #fff;
      img {
        margin: 0 10px;
      }
      &:hover {
        background-color: $button-hovered;
      }
      &:active, &.active {
        background-color: $button-active;
      }

    }
    .repository-container {
      .title-as-btn {
        & {
          &:hover {
            //background-color: $button-hovered;
          }
        }
      }
    }
    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      height: 40px;
      width: 100%;
      &:hover {
        background: $button-hovered;
      }
      a {
        display: block;
        width: 189px;
        filter: invert(1);
        overflow: hidden;
        transition: $transition-duration width;
        &.cropped {
          width: 35px;
          transition: $transition-duration width;
        }
        img {
          width: 189px;
          height: 30px;
        }
      }
    }
    .dashboards {
      &-list {
        margin: 10px 0;
        a {
          display: block;
          font-size: 12px;
          padding: 5px 0 7px 43px;
          color: #fff;
          &:hover {
            background: $button-hovered;
          }
          &:active, &.active {
            background: $button-active;
          }
          &.disabled {
            pointer-events: none;
            opacity: .6;
          }
        }
      }
    }
    & > form {
      width: 100%;
      padding-bottom: 60px;
    }
  }
  p {
    font-size: 12px;
    margin-top: 5px;
  }
}
.overlay {
  position: relative;
  min-width: $open-menu-width;
  height: 100%;
  pointer-events: none;
  &.open {
    width: $open-menu-width;
    transition: .1s min-width;
    &+.wrapper {
      width: calc(100% - #{$open-menu-width});
    }
  }
  &.close {
    min-width: $closed-menu-width;
    transition: .1s min-width;
    &+.wrapper {
      width: calc(100% - #{$closed-menu-width});
    }
  }
}
.burger {
  position: absolute;
  right: -20px;
  top: 0;
  display: flex;
  align-items: center;
  width: 24px;
  height: 40px;
  font-size: 20px;
  border: none;
  background-size: 14px;
  background-repeat: no-repeat;
  background-color: transparent;
  color: #fff;
  &-arrow {
    opacity: 0;
    transition: .15s opacity;
  }
  svg polyline {
    stroke: #fff;
  }
  &:hover {
    color: #000;
    transition: .15s all;
    .burger-arrow {
      opacity: 1;
      transition: .15s opacity;
    }
  }
}
