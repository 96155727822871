@import './../../../lib/styles/colors';
@import './../../../lib/styles/mixins';
details, summary, .categories-list  {
  margin: 0;
  padding: 5px 0;

}

details {
  cursor: pointer;
  &:last-child {
    padding-bottom: 0;
  }
  summary {
    padding: 5px 0 7px 43px;
    list-style: none;
    font-size: 12px;
    color: #fff;
    &:hover {
      color: lighten(#cecece, 15%);
    }
    &:active {
      color: lighten(#cecece, 30%);
    }
    &::before {
      content: '> ';
      position: relative;
      width: 14px;
      display: inline-block;
      text-align: center;
      font-size: 12px;
      margin: 0 3px;
      transform: rotate(90deg);
      transition: .2s transform;
    }
  }
  &[open] summary {
    &::before {
      transform: rotate(270deg);
      transition: .2s transform;
    }
  }

  .categories-list {
    padding: 0;
    color: #adadad;
    font-size: 12px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 5px 10px 7px 62px;
      word-break: break-all;
    }
    &:hover {
      background-color: $button-hovered;
    }
    &:active, &.active {
      background-color: $button-active;
    }
  }
}
