@import "../../lib/styles/args.scss";
@import "../../lib/styles/mixins.scss";

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: $regular-text-color;
    height: 60px;
    &:last-child {
        height: 40px;
        border-bottom: 1px solid #898989;
    }
    .logo-container {

    }
    .info-container {
        margin-right: 25px;
        .info {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: $regular-text-color;

            & > span, & > a {
                margin: 0 10px;
            }
        }
        select {
            font-size: 16px;
            border: none;
            background: transparent;
            outline: none;
            color: $regular-text-color;
            &:hover {
                color: #000;
            }
        }
        span {
            font-size: 16px;
        }
        .logout {
            height: 32px;
            font-size: 16px;
            background: transparent;
            color: $regular-text-color;
            &:hover {
                color: #000;
            }
        }
    }
    .container {

    }
    .header {

    }
    .wrapper:last-child {
       border-bottom: 2px solid #acacac;

    }
    .wrapper .content:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;




    }
    .wrapper:first-child {
        .content {
            display: flex;
            justify-content: flex-start;
            padding: 15px 10px;

        }
    }
    .wrapper:last-child {
        .content {
            display: flex;
            justify-content: flex-end;
        }
    }
    img {
        width: 188px;
        height: auto;
    }


}
