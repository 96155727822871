@import './../../../../lib/styles/mixins';

.expandedContainer {
  display: grid;
  grid-auto-rows: 550px;
  grid-template-columns: repeat(auto-fit,minmax(600px, 1fr));
  width: 100%;
  grid-gap: 25px;
  padding: 25px 25px 60px;
  height: 100%;
  transition: 0.1s width;

  &.empty {
    grid-auto-rows: initial;
    padding: 0;
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 100%;
    overflow: hidden;
    h3 {
      margin: 10px auto;
      max-width: 350px;
      width: 85%;
    }
    .logo {
      position: absolute;
      right: -40px;
      bottom: -40px;
      width: 250px;
      height: 250px;
      opacity: .5;

    }
  }
  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #adadad;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid $menu-default-background;
      margin: 0 10px;
      h4 {
        display: flex;
        width: 90%;
        align-items: center;
      }
      &-controls {
        width: 15%;
        text-align: right;
        img {
          width: 22px;
        }
        & > * {
          margin:  0 5px;
        }
      }
      .wrapper1{
        position: relative;
        width: 150px;
        display: inline-flex;
        align-items: center;
        margin-top: 2px;
        margin-left: 5px;
        .edit {
          padding: 0 3px;
          box-shadow: none;
          background: transparent;
          color: #000;
          border: 1px solid #e6e6e6;
          font-size: 12px;
          cursor: pointer;
          &:hover {
            border: 1px solid rgb(204, 204, 204);
            background: #f6f6f6;
          }
        }
        .dots {
          position: absolute;
          right: 0;
          background: #fff;
          border: 1px solid rgba(255, 255, 255, 0.49);
        }
        input[type=submit] {
          position: relative;
          font-size: 16px;
          margin: 0 5px;
          width: 32px;
          border: none;
          background: transparent;
          cursor: pointer;
        }
        input[type=text] {
          font-size: 16px;
          font-weight: 700;
          padding: 0 2px;
          max-width: 150px;
          min-width: 20px;
          border: none;
          &:not(&:focus) {
            cursor: pointer;
          }
          &:focus {
            border: none;
          }
        }
        span#hide {
          position: absolute;
          opacity: 0;
          height: 100%;
          z-index: -100;
          display: block;
          white-space: pre
        }
      }
    }
    &-body {
      display: flex;
      align-items: center;
      min-height: 250px;
      padding: 10px 5px;
      .pending-text {
        margin: 0 auto;
      }
      svg {
        width: 100%;
        height: 100%;
        max-width: 700px;
        margin: 0 auto;
      }
      .table-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: auto;
        table {
          width: 100%;
          max-width: 745px;
          border: 1px solid black;
          border-collapse: collapse;
          margin: 0 auto;
          table, th, td {
            text-align: center;
            border: 1px solid black;
            border-collapse: collapse;
            padding: 3px;
          }
        }
      }
    }
    &-footer {
      text-align: center;
      padding: 15px 20px;
    }
  }
}
@media screen and (max-width: 800px){
  .expandedContainer {
    .item {
      grid-template-columns: 1fr;
    }
  }
}
