@import './../lib/styles/mixins.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#root {
  height: 100%;
}
button, select {
  font-family: 'Lato', sans-serif;
  cursor: pointer;
}

button,
input[role='button'] {
  align-items: center;
  text-align: center;
  font-size: 12px;
  border: none;
  background: transparent;
  color: #000;
  &:disabled {
    cursor: default;
  }
}
a, a:visited {
  color: inherit;
  text-decoration: none;
}
ul  {
  list-style-type: none;
}
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.25);
  z-index: 1;
  select {
    height: initial;
  }
  &-content {
    &.filters {
      width: initial;
      height: initial;
    }
    display: flex;
    flex-direction: column;
    width: 475px;
    padding: 25px;
    background-color: #fff;
    h3 {
      text-align: center;
      margin-bottom: 20px;
    }
    .calendar {
      display: flex;
      margin: 0 auto;
      width: 100%;
      height: 370px;
    }
    &-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      .as-btn {
        cursor: pointer;
        align-items: center;
        text-align: center;
        border: none;
        width: 100px;
        height: 40px;
        background: #7b7b7b;
        color: #fff;
        svg {
          display: none;
        }
      }
      button, input[role='button'] {
        height: 35px;
        margin: 0 10px;
        padding: 0 20px;
        color: #fff;
        background: $button-active;
        cursor: pointer;
        &:hover {
          background: $button-hovered;
        }
      }
    }
  }
}
