@import './../../../lib/styles/colors';
@import './../../../lib/styles/mixins';

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label, .send-btn {
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: $regular-text-color;
    color: #fff;
    transition: 0.1s background-color, color;
  }
}
.inputfile + label * {
  pointer-events: none;
}
.send-btn-animation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  margin-right: 3px;
  animation: 1s infinite-rotation infinite linear;
}
.upload-modal {
  position: fixed;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.25);
  color: #0d0d0d;
  &-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    width: 400px;
    height: 300px;
    margin: auto;
    padding: 10px 15px;
    background: #fff;
    border: 1px solid #000;
    &-categories {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-input {
        height: 30px;
      }
      .radio-container {
        margin: 3px 0 3px 5px;
        label {
          margin-left: 5px;
        }
      }
    }
    &-controls {
      display: flex;
      justify-content: space-between;
    }
  }

}
@keyframes infinite-rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }

}
