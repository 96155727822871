@import './../../../lib/styles/mixins.scss';

.rdrDateRangePickerWrapper {
  &.disabled {
    .rdrMonth {
      opacity: 0.8;
      filter: grayscale(.5);
      pointer-events: none;
    }
  }
.rdrStaticRanges  {
  button.rdrStaticRange.rdrStaticRangeSelected {
    background: #eff2f7;
    font-weight: 700;
  }
  button.rdrStaticRange {
    background: #eff2f7;
    font-weight: 700;

  }

  .rdrStaticRange#active .rdrStaticRangeLabel {
    background: #ffffff;
    font-weight: 700;
    color: #000;
    &:hover {
      background: #ffffff;
      font-weight: 700;
      color: #000;
    }
  }
}
}
main {
  &> .rdrDateRangePickerWrapper {
    width: 80%;
  }
  .rdrDefinedRangesWrapper {
    display: block;
    overflow: auto;
  margin-right: 25px;
    .rdrStaticRanges {
      //align-items: stretch;
      button {
        width: 135px;
        border: none;
        font-size: 10px;
        background: #fff;
        color: #4d4d4d;
        height: 24px;
        margin: 5px;
        text-transform: uppercase;
        box-shadow: 0 0 4px rgb(0 0 0 / 25%);
        & > span {

          padding: 6px 10px;
        }
        .rdrStaticRangeLabel {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
      span {
        color: #0a0a0a;

      }
    }
  }
}

.rdrDefinedRangesWrapper {
  display: none;
}
.rdrDateRangePickerWrapper .rdrCalendarWrapper {
  font-size: 10px;
}
.rdrCalendarWrapper .rdrMonth {
  width: 23.667em;
  height: 18.5em;
}
.rdrCalendarWrapper.rdrDateRangeWrapper {
  width: 100%;
  .rdrMonthAndYearWrapper {
    select {
      background: inherit;
      color: inherit;

    }
    .rdrNextPrevButton {
      width: 24px;
      height: 24px;
      background: inherit;

      box-shadow: 0 0 4px rgb(0 0 0 / 25%);
      i {
        margin: auto;
      }
      &:hover {
        background: #c7c7c7;
      }
    }
  }

  .rdrDays {
    button {
      background: #fff;
      height: 24px;
      & > span {
          width: auto;
      }
    }
  }
}
.rdrDateRangePickerWrapper {
  width: 100%;
}
.rdrDateRangePickerWrapper .rdrNextPrevButton {
  margin: 0;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 30px;
}
.react-calendar__navigation button {
  width: 50px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  height: 20px;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #acacac;
}

.react-calendar__tile {
  width: 35px;
  height: 35px;
  text-align: center;
  background: none;
  border-radius: 50%;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #d2e3fc;
  color: #0e76b9;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.rdrDays .rdrDay {
  &.rdrDayToday .rdrDayNumber {
    bottom: 4px;
    span:after {
      background-color: transparent !important;
    }
  }
}

.template {
  .rdrDays {
    button {
      box-shadow: none;
      margin: 0;
      span {
        margin: 0;
        top: 0;
      }
    }
  }
  .rdrNextButton i {
    margin: initial;
  }
}
