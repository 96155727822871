@import './../../../lib/styles/colors';
@import './../../../lib/styles/mixins';

$transition-duration: .15s;
@mixin col-prop() {
  height: 590px;

  border: 1px solid #7A8A97;
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.25);

};
@mixin button-prop() {

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 24px;
  font-size: 12px;
  color: #fff;
  background-color: $menu-default-background;
  & > span {
    width: 116px;
  }
  & > .btn-icon {
    position: relative;
    left: 5px;
    display: flex;
  }
  &:hover {
    background-color: $button-hovered;
  }
}
.wrapper-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.template {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  .disabled-link, button:disabled {
    pointer-events: none;
    opacity: .6;
  }

  .Dropdown-root {
    width: 150px;
    height: 24px;
    cursor: pointer;
    .Dropdown-control {
      padding: 0;
      background-color: $menu-default-background;
      color: #fff;
      .Dropdown-arrow-wrapper span {
        top: 10px;
      }
    }
    .Dropdown-menu {
      background-color: $menu-default-background;
      .Dropdown-option {
        height: 24px;
        padding: 0;
        color: #fff;

        &:hover, &.is-selected {
          background-color: darken($menu-default-background, 5%);

        }
      }
    }
  }
  button {
    @include button-prop();
    &.generate {
      padding: 0 5px;
    }
    &.remove {
      justify-content: center;
      width: 165px;

      span {
        width: initial;
      }
    }

  }
  a, a:visited {
    @include button-prop();

    &.view-source {
      width: 115px;
    }

    &.back {
      width: 150px;
      height: 24px;
      font-weight: 500;
    }
  }
  &-left {
    .report-name-input{
      padding: 3px;
      border: none;
      margin-left: 5px;
      font-size: 16px;
      color: #000;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      &.visible {
        visibility: visible;
      }

      &.hidden {
        visibility: hidden;
      }
    }
    .wrapper1{
      position: relative;
      width: 150px;
      display: inline-flex;
      align-items: center;
      margin-left: 5px;
      .edit {
        width: 28px;
        height: 28px;
        padding: 2px;
        border: none;
        box-shadow: none;
        background: transparent;
        color: #000;
        cursor: pointer;
        img {
          width: 22px;
        }
      }
      input[type=submit] {
        position: relative;
        font-size: 16px;
        margin: 0 5px;
        width: 32px;
        border: none;
        background: transparent;
        cursor: pointer;
      }
      input[type=text] {
        font-size: 16px;
        font-weight: 700;
        padding: 0 2px;
        max-width: 150px;
        min-width: 20px;
        &:not(&:focus) {
          cursor: pointer;
        }
      }
      span#hide {
        position: absolute;
        opacity: 0;
        height: 100%;
        z-index: -100;
        display: block;
        white-space: pre
      }
    }

    .static{
      position: absolute;
      padding: 8px;
      pointer-events: none;
    }
  }
  &-left, &-right {
    h2 {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0 auto;
      padding: 10px;
      font-size: 16px;
      text-align: center;
      color: #000;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        width: calc(100% - 20px);
        height: 1px;
        margin: 0 auto;
        background-color: $button-active;
      }
      .back-wrapper {
        margin: 5px 0;
      }
    }
  }
  &-left {
    width: 75%;
    max-width: 1200px;
    &-chart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 25px);
      margin: 0 auto;
      @include col-prop();
      .chart-wrapper {
        .chart {
          width: 80%;
        }
      }
      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 80%;
        width: 100%;
        padding: 10px;
        .loader-wrapper {
          position: absolute;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 745px;
          height: 100%;
          margin-left: 50%;
          margin-top: 5px;
          transform: translateX(-50%);
        }

        .svg-wrapper {
          overflow: hidden;
          opacity: 1;
          transition: $transition-duration opacity;

          svg {
            width: 100%;
            max-width: 745px;
            height: 100%;
            margin-left: 50%;
            margin-top: 5px;
            transform: translateX(-50%);
          }
          &.blur {
            opacity: .5;
            transition: $transition-duration opacity;

          }
          &.no-chart {
            max-width: 1200px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            overflow: auto;
            table {
              width: 100%;
              max-width: 745px;
              border: 1px solid black;
              border-collapse: collapse;
              table, th, td {
                text-align: center;
                border: 1px solid black;
                border-collapse: collapse;
                padding: 3px;
              }
            }
          }
        }
      }

      &-selects {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px;
      }
    }
    &-controls {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 20px 0;
      width: 95%;
      & > button, .select-wrapper {
        margin-right: 20px;
        width: 165px;
      }

    }
    .select-wrapper {
      display: flex;
      justify-content: flex-start;
      position: relative;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 12px;
      background-color: $button-active;
      color: #fff;

      span {
        color: #fff;
      }
      img {
        margin: 0 10px;
        width: 12px;
      }
      &.select {
        justify-content: space-between;
        width: 100%;
        background-color: transparent;
        &:before {
          content: '>';
          position: absolute;
          font-size: 18px;
          color: #fff;
          left: 7px;
          transform: rotate(90deg );
        }
      }
      &:hover {
        select {
          background: $button-hovered;
          color: #fff;
        }
        &:hover {
          &::before {
            color: #fff;
          }
        }
      }
    }
    .custom-dropdown {
      cursor: pointer;
      background: $menu-default-background;
      &-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        transition: .15s opacity;
        z-index: 1;
        background: $menu-default-background;

        a, span {
          display: block;
          text-align: left;
          margin: 0;
          padding-left: 5px;
          &:hover {
            background-color: $button-hovered;
          }
        }
      }
      &:hover {
        .custom-dropdown-list {
          pointer-events: auto;
          opacity: 1;
          transition: .15s opacity;
        }
      }
    }
  }

  &-right {
    width: 25%;
    max-width: 250px;
    &-description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      @include col-prop();
      height: 90%;
      min-height: 150px;
      &-info {
        &-text {
          margin: 10px;
          &> div {
            font-size: 12px;
            color: #000;
            &:first-child {
              font-weight: 700;
            }
          }
        }
      }
      .note {
        margin: 10px;
        padding: 5px;
        font-size: 12px;
        border: 1px solid #7A8A97;
        box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.25);
      }
    }
  }
  &.small {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .template-left, .template-right {
      width: 100%;
      max-width: initial;
    }
    .template-left-controls {
      flex-wrap: wrap;
      width: 100%;
      padding: 0 15px;
      & > * {
        margin-bottom: 15px;
      }
    }
    .template-right {
      width: 97%;
      &-description-info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &-text {
          width: 25%;
          min-width: 170px;
        }
      }
    }
  }
}

@keyframes infinite-rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }

}
@media print {
  .wrapper {
    header, nav, .nav, .overlay, .wrapper1 {
      display: none;
    }
    canvas {
      min-height: 100%;
      max-width: 100%;
      max-height: 100%;
      height: auto!important;
      width: auto!important;
    }
  }
  .template {
    padding-top: 5px;
    width: 100%;
    height: 100%;
    &-left, &-right {
      margin: 10px 0;
      width: 100%;
      &-controls {
        display: none;
      }
    }
    &-left {
      flex: 1 0 100%;
      height: 440px;
      &-chart {
        justify-content: flex-start;
        max-width: initial;
        width: 100%;
        &-selects {
          display: none;
        }
        &-container {
          align-items: center;
        }
        &-wrapper {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 90%;
          .chart {
            margin-bottom: 20px;
          }
          .legends {
            justify-content: space-evenly;
            height: 300px;
            overflow: auto;
            flex-direction: row;
            width: 90%;
          }
        }
      }
    }
    &-right {
      height: 100px ;
      flex-direction: row;
      &-description {
        margin-top: 10px;
        min-height: 10px;
        height: auto;

        &-info {
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          &-text {
            display: block;
            float: left;
            width: 25%;
          }
        }
      }
    }
  }
}
