.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgb(176, 176, 176) 0%, rgb(241, 241, 241) 50%);
  .logo-image {
    position: absolute;
    top: 2%;
    left: 3%;
    width: 200px;
    height: auto;
  }
  .logo {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 280px;
    width: 310px;
    background-repeat: no-repeat;
    background-position: 65px 110px;
    opacity: .5;
  }
  &-content {
      text-align: center;
    max-width: 600px;
    width: 100%;
    &-link {
      display: block;
      margin: 5px;
    }
    button {
      width: 100px;
      height: 30px;
      color: #fff;
      margin: 10px;
      background: #217af0;
      border: none;
      border-radius: 3px;
    }
  }

}
