@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import '../lib/styles/args.scss';



.wrapper {
  display: flex;
  flex-direction: column;
  color: #000;
  min-height: 100%;
  height: 100%;
}
.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}
main {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
  width: 100%;
    .wrapper .content {
    display: flex;
      height: 95%;
    main {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-start;
      height: 100%;
    }
    .dashboard {
      width: 100%;
    }
    .main {
        width: 75%;
    }
  }
}
